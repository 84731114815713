<template>
    <div>
        <v-overlay v-model="dialog" color="primary" style="z-index: 6"></v-overlay
        ><v-dialog
            v-model="dialog"
            content-class="d-flex elevation-0 position-relative"
            transition="dialog-bottom-transition"
            persistent
            width="900"
            elevation="0"
            hide-overlay
        >
            <v-card
                elevation="0"
                color="white"
                class="position-relative cursor-pointer white overflow-hidden"
                v-if="dialog"
            >
                <v-container class="pa-0">
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-row class="modal-header pt-0">
                            <v-col cols="12" class="px-6 d-flex align-center">
                                <div>
                                    <span class="font-size-24 primary--text">{{
                                        $t('resources.new_message')
                                    }}</span>
                                </div>
                                <v-btn icon color="primary" class="ml-auto" @click="exitCard">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row class="px-10">
                            <v-col cols="12" class="pb-0">
                                <v-row class="pa-0 d-flex flex-row align-center">
                                    <v-col cols="2" class="text-center pa-0">
                                        <span class="primary--text">{{
                                            $t('resources.to_groups')
                                        }}</span>
                                    </v-col>
                                    <v-col cols="10" class="pa-0">
                                        <v-autocomplete
                                            v-model="groupSelected"
                                            :items="groups"
                                            :disabled="loadAction"
                                            chips
                                            dense
                                            color="primary lighten-2"
                                            item-text="name"
                                            item-value="id"
                                            multiple
                                            clearable
                                            required
                                            :rules="groupsRules"
                                        >
                                            <template v-slot:selection="data">
                                                <v-chip
                                                    v-bind="data.attrs"
                                                    :input-value="data.selected"
                                                    close
                                                    @click="data.select"
                                                    @click:close="removeGroup(data.item)"
                                                >
                                                    <div v-if="data.item.type === $t('course')">
                                                        <v-avatar left>
                                                            <v-icon class="primary--text"
                                                                >mdi-clipboard-check</v-icon
                                                            >
                                                        </v-avatar>
                                                        <span class="text-capitalize">{{
                                                            data.item.name
                                                        }}</span>
                                                    </div>
                                                    <div v-else>
                                                        <v-avatar left>
                                                            <v-icon
                                                                :class="`${data.item.color}--text`"
                                                                >mdi-circle</v-icon
                                                            >
                                                        </v-avatar>
                                                        <span
                                                            class="text-capitalize primary--text"
                                                            >{{ data.item.name }}</span
                                                        >
                                                    </div>
                                                </v-chip>
                                            </template>
                                            <template v-slot:item="data">
                                                <template>
                                                    <v-list-item-avatar
                                                        v-if="data.item.type === $t('course')"
                                                    >
                                                        <v-icon
                                                            :class="`${data.item.color}--text`"
                                                            >{{ 'mdi-clipboard-check' }}</v-icon
                                                        >
                                                    </v-list-item-avatar>

                                                    <v-list-item-content
                                                        class="text-capitalize px-2"
                                                    >
                                                        <v-list-item-title class="py-1"
                                                            >{{ data.item.name }}
                                                            <span
                                                                v-if="data.item.type === $t('tier')"
                                                                :class="`ml-2 pa-5 ${data.item.color} white--text`"
                                                                >{{ data.item.value }}</span
                                                            ></v-list-item-title
                                                        >
                                                        <v-list-item-subtitle
                                                            v-html="data.item.type"
                                                        ></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" class="py-0">
                                <v-row class="pa-0 d-flex flex-row align-center">
                                    <v-col cols="2" class="text-center pa-0">
                                        <span class="primary--text">{{
                                            $t('resources.to_students')
                                        }}</span>
                                    </v-col>
                                    <v-col cols="10" class="pa-0">
                                        <v-autocomplete
                                            v-model="studentSelected"
                                            :items="studentList"
                                            :disabled="loadAction"
                                            chips
                                            dense
                                            color="primary lighten-2"
                                            item-text="name"
                                            item-value="id"
                                            multiple
                                            clearable
                                            required
                                            :rules="studentsRules"
                                        >
                                            <template v-slot:selection="data">
                                                <v-chip
                                                    v-bind="data.attrs"
                                                    :input-value="data.selected"
                                                    close
                                                    @click="data.select"
                                                    @click:close="removeStudents(data.item)"
                                                >
                                                    <v-avatar left>
                                                        <UserAvatar
                                                            :user-name="data.item.name"
                                                            :avatar="data.item.avatar"
                                                        ></UserAvatar>
                                                    </v-avatar>
                                                    {{ data.item.name }}
                                                </v-chip>
                                            </template>
                                            <template v-slot:item="data">
                                                <template v-if="typeof data.item !== 'object'">
                                                    <v-list-item-content
                                                        v-text="data.item"
                                                    ></v-list-item-content>
                                                </template>
                                                <template v-else>
                                                    <v-list-item-avatar>
                                                        <UserAvatar
                                                            :user-name="data.item.name"
                                                            :avatar="data.item.avatar"
                                                        ></UserAvatar>
                                                        <!--                                            <img :src="data.item.avatar" alt="user avatar" />-->
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title
                                                            v-html="data.item.name"
                                                        ></v-list-item-title>
                                                        <v-list-item-subtitle
                                                            v-html="data.item.class"
                                                        ></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" class="py-0">
                                <v-row>
                                    <v-col cols="2" class="pa-0 text-center">
                                        <span class="primary--text">{{
                                            $t('subject')
                                        }}</span></v-col
                                    >
                                    <v-col cols="10" class="pa-0">
                                        <v-text-field
                                            dense
                                            v-model="subjectMessage"
                                            :disabled="loadAction"
                                            :placeholder="$t('subject')"
                                            required
                                            :rules="subjectRules"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" class="py-0">
                                <v-row>
                                    <v-col cols="2" class="pa-0 text-center">
                                        <span class="primary--text">{{
                                            $t('message')
                                        }}</span></v-col
                                    >
                                    <v-col cols="10" class="pa-0">
                                        <v-textarea
                                            dense
                                            :placeholder="$t('resources.new_message')"
                                            :disabled="loadAction"
                                            v-model="bodyMessage"
                                            required
                                            :rules="bodyRules"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row class="px-6 py-2">
                            <v-card-actions class="w-100">
                                <v-btn
                                    outlined
                                    color="secondary"
                                    class="ml-auto more-border text-capitalize"
                                    @click="sendMessage"
                                    :disabled="!valid"
                                    :loading="loadAction"
                                >
                                    <v-icon class="mr-2">mdi-send</v-icon>
                                    <span>{{ $t('resources.send_message') }}</span>
                                </v-btn>
                            </v-card-actions>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import UserAvatar from '../../core/UserAvatar'
import messages from '../../../services/messages'

export default {
    name: 'ResendMessageModal',
    components: { UserAvatar },
    props: {
        dialog: { type: Boolean, default: false },
        reBody: { type: String, default: '' },
        reSubject: { type: String, default: '' },
        messageParentId: { type: Number, default: 0 },
    },
    mounted() {
        if (this.reBody) this.bodyMessage = this.reBody
        if (this.reSubject) this.subjectMessage = this.reSubject
    },
    methods: {
        ...mapActions(['setShowResendMessageModal']),
        exitCard() {
            this.$emit('close-modal')
        },
        removeStudents(item) {
            const index = this.studentSelected.indexOf(item.id)
            if (index >= 0) this.studentSelected.splice(index, 1)
        },
        removeGroup(item) {
            const index = this.groupSelected.indexOf(item.id)
            if (index >= 0) this.groupSelected.splice(index, 1)
        },
        async sendMessage() {
            if (this.$refs.form.validate()) {
                this.loadAction = true
                const data = {
                    userFrom: {
                        id: this.user.id,
                    },
                    userToList: this.studentSelected.map((i) => ({ id: i })),
                    message: {
                        id: this.messageParentId,
                        subject: this.subjectMessage,
                        message: this.bodyMessage,
                    },
                    groupList: this.mapGroupsList(),
                }
                await messages.reSendMessage(data)

                this.setShowResendMessageModal()
            }
            this.loadAction = false
        },
        mapGroupsList() {
            let result = []
            let groupsList = this.groupSelected
                .filter((i) => typeof i === 'number')
                .map((i) => ({ course: { id: i } }))

            let tierList = this.groupSelected
                .filter((i) => typeof i === 'string')
                .map((i) => this.getTierByString(i))

            if (tierList?.length) {
                tierList.forEach((tier) => {
                    groupsList.forEach((group) => {
                        result.push({ ...group, ...tier })
                    })
                })
            } else {
                result = [...result, ...groupsList]
            }

            return result
        },
        validateGrouos(v) {
            let groupsList = v.filter((i) => typeof i === 'number')
            let tierList = v.filter((i) => typeof i === 'string')
            return !(!tierList.length || !groupsList.length)
        },
        getTierByString(value = null) {
            switch (value) {
                case 'TIER 1':
                    return { tier: 1 }
                case 'TIER 2':
                    return { tier: 2 }
                case 'TIER 3':
                    return { tier: 3 }
                default:
                    return {}
            }
        },
        getTiers() {
            return [
                {
                    name: `${this.$t('tier')} 1`,
                    id: 'TIER 1',
                    color: 'accent',
                    type: this.$t('tier'),
                    value: '61% - 100%',
                },
                {
                    name: `${this.$t('tier')} 2`,
                    id: 'TIER 2',
                    color: 'warning',
                    type: this.$t('tier'),
                    value: '41% - 60%',
                },
                {
                    name: `${this.$t('tier')} 3`,
                    id: 'TIER 3',
                    color: 'primary',
                    type: this.$t('tier'),
                    value: '0% - 40%',
                },
            ]
        },
    },
    computed: {
        ...mapState({
            courses: (state) => state.userData.courses,
            students: (state) => state.userData.students,
            user: (state) => state.userData.user,
        }),
        studentList() {
            let result = []
            this.courses.forEach((i) => {
                result.push({ header: i.name })
                const tempArr = this.students.filter((j) => j.course.id === i.id)
                result = [
                    ...result,
                    ...tempArr.map((k) => ({
                        name: this.$utils.getFullName(k),
                        id: k.id,
                        avatar: k.avatar,
                        class: i.name,
                    })),
                ]
            })

            return result
        },
        groups() {
            return [
                ...[{ header: this.$utils.upperFirstLetter(this.$t('courses')) }],
                ...this.courses.map((item) => ({
                    name: item.name,
                    id: item.id,
                    color: 'primary',
                    type: this.$t('course'),
                })),
                ...[{ header: this.$utils.upperFirstLetter(this.$t('tier')) }],
                ...this.getTiers(),
            ]
        },
        subjectRules() {
            return [(v) => !!v || this.$t('resources.subject_required')]
        },
        bodyRules() {
            return [(v) => !!v || this.$t('resources.body_required')]
        },
        studentsRules() {
            return [
                (v) => !!v || this.$t('resources.student_required'),
                (v) => v.length || this.$t('resources.student_required'),
            ]
        },
        groupsRules() {
            return [
                (v) => !!v || this.$t('resources.group_required'),
                (v) => v.length || this.$t('resources.group_required'),
                (v) => this.validateGrouos(v) || this.$t('resources.group_required'),
            ]
        },
    },
    data: () => ({
        loadAction: false,
        valid: true,
        studentSelected: [],
        groupSelected: [],
        bodyMessage: '',
        subjectMessage: '',
    }),
}
</script>

<style scoped>
.modal-header {
    background-color: #f7f6fb;
    border-bottom: 3px solid #cbc6db;
}
</style>
