<template>
    <v-container>
        <v-row>
            <v-col cols="3" class="pr-0">
                <v-card
                    class="border-card-options d-flex flex-column align-center py-3"
                    height="700"
                >
                    <div class="mt-10 w-100">
                        <div
                            :class="`w-100 py-2 px-10 text-left my-2 cursor-pointer ${
                                currentOption === 0 ? ' border-selected ' : ''
                            }`"
                            @click="setOption()"
                        >
                            <span class="primary--text d-flex flex-row align-center"
                                ><v-icon class="primary--text mr-2">mdi-email-newsletter</v-icon>
                                {{ $t('resources.inbox') }}</span
                            >
                        </div>
                        <div
                            :class="`w-100 py-2 px-10 text-left my-2 cursor-pointer ${
                                currentOption === 1 ? ' border-selected ' : ''
                            }`"
                            @click="setOption(1)"
                        >
                            <span class="primary--text d-flex flex-row align-center"
                                ><v-icon class="primary--text mr-2">mdi-send</v-icon>
                                {{ $t('resources.sent_messages') }}</span
                            >
                        </div>
                        <div
                            :class="`w-100 py-2 px-10 text-leftmy-2 cursor-pointer ${
                                currentOption === 2 ? ' border-selected ' : ''
                            }`"
                            @click="setOption(2)"
                        >
                            <span class="primary--text d-flex flex-row align-center"
                                ><v-icon class="primary--text mr-2">mdi-folder-outline</v-icon>
                                {{ $t('resources.archived') }}</span
                            >
                        </div>
                        <div
                            :class="`w-100 py-2 px-10 text-left my-2 cursor-pointer d-flex flex-row align-center ${
                                currentOption === 3 ? ' border-selected ' : ''
                            }`"
                            @click="setOption(3)"
                        >
                            <span class="primary--text d-flex flex-row align-center"
                                ><v-icon class="primary--text mr-2">mdi-delete-outline</v-icon>
                                {{ $t('resources.trash') }}</span
                            >
                        </div>
                    </div>
                </v-card>
            </v-col>
            <v-col cols="9" class="pl-0">
                <v-card class="border-card-message" height="700">
                    <v-container>
                        <v-row>
                            <v-col cols="12" class="d-flex align-start justify-center flex-row">
                                <v-btn
                                    text
                                    color="primary"
                                    class="text-capitalize"
                                    :disabled="!selected.length"
                                    @click="topAction('trash')"
                                    :loading="loadingData"
                                    ><v-icon class="primary--text mr-2">mdi-delete-outline</v-icon
                                    >{{ $t('resources.delete') }}</v-btn
                                >
                                <v-btn
                                    text
                                    color="primary"
                                    class="text-capitalize"
                                    :disabled="!selected.length"
                                    @click="topAction('archived')"
                                    :loading="loadingData"
                                    ><v-icon class="primary--text mr-2">mdi-folder-outline</v-icon>
                                    <span>{{ $t('resources.file') }}</span></v-btn
                                >
                                <div class="ml-auto d-flex align-center justify-space-around">
                                    <v-btn
                                        icon
                                        color="primary"
                                        :disabled="page + 1 === 1"
                                        @click="prevPage"
                                        ><v-icon>mdi-menu-left</v-icon></v-btn
                                    >
                                    <span
                                        class="font-size-12"
                                        v-html="
                                            $t('resources.paginator', [
                                                page + 1,
                                                totalPages,
                                                totalElements,
                                            ])
                                        "
                                    ></span>
                                    <v-btn
                                        icon
                                        color="primary"
                                        :disabled="page + 1 === totalPages"
                                        @click="nextPage"
                                        ><v-icon>mdi-menu-right</v-icon></v-btn
                                    >
                                </div>
                            </v-col>
                            <v-col cols="12">
                                <v-data-table
                                    v-model="selected"
                                    :headers="headers"
                                    :items="mapContent"
                                    :items-per-page="20"
                                    hide-default-footer
                                    show-select
                                    item-key="id"
                                    :loading="loadingData"
                                    @click:row="dataTableClickAction"
                                >
                                    <template v-slot:item.name="{ item }">
                                        <div class="d-flex justify-start align-center flex-row">
                                            <UserAvatar
                                                :user-name="item.name"
                                                :avatar="item.avatar"
                                                class="mr-2"
                                            />
                                            <span class="grey--text">{{ item.name }}</span>
                                        </div>
                                    </template>
                                    <template v-slot:item.subject="{ item }">
                                        <span class="grey--text">{{ item.subject }}</span>
                                    </template>
                                    <template v-slot:item.date="{ item }">
                                        <span class="grey--text">{{
                                            $moment(item.date).format('MMM DD YYYY / hh:mm')
                                        }}</span>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <NewMessageModal :dialog="showNewMessageModal" @close-modal="setShowNewMessageModal" />
        <ReplyMessageModal
            :dialog="showReplyModal"
            :user-id="selected.length ? selected[0].userId : 0"
            @close-modal="setShowReplyModal"
            :re-subject="selected.length ? `RE: ${selected[0].subject}` : ''"
            v-if="showReplyModal"
        />
        <ResendMessageModal
            :dialog="showResendMessageModal"
            @close-modal="setShowResendMessageModal"
            v-if="showResendMessageModal"
            :message-parent-id="selected.length ? selected[0].id : 0"
            :re-subject="selected.length ? `RE: ${selected[0].subject}` : ''"
            :re-body="selected.length ? `${selected[0].body}` : ''"
        />
        <MessageModal
            :dialog="showMessage"
            v-if="currentMessage"
            :message="currentMessage"
            @close-modal="closeMessageModal"
        ></MessageModal>
    </v-container>
</template>

<script>
import UserAvatar from '../../core/UserAvatar'
import NewMessageModal from '../modals/NewMessageModal'
import messages from '../../../services/messages'
import { mapState, mapActions } from 'vuex'
import ReplyMessageModal from '../modals/ReplyMessageModal'
import ResendMessageModal from '../modals/ResendMessageModal'
import MessageModal from '../modals/MessageModal'

export default {
    name: 'MessageCards',
    components: {
        ResendMessageModal,
        ReplyMessageModal,
        NewMessageModal,
        UserAvatar,
        MessageModal,
    },
    data: () => ({
        currentOption: 0,
        page: 0,
        selected: [],
        loadingData: false,
        arrMessages: '',
        totalElements: '',
        totalPages: '',
        content: [],
        currentMessage: null,
        showMessage: false,
    }),
    methods: {
        ...mapActions(['setShowNewMessageModal', 'setShowReplyModal', 'setShowResendMessageModal']),
        async setOption(option = 0) {
            if (this.currentOption !== option) {
                this.currentOption = option
                this.page = 0
                await this.getData()
            }
        },
        dataTableClickAction(data, options) {
            console.log(data, options)
            this.currentMessage = data
            this.showMessage = true
        },
        closeMessageModal() {
            this.currentMessage = null
            this.showMessage = false
        },
        openOrCloseModal() {
            this.setShowNewMessageModal()
        },
        async getData() {
            this.loadingData = true
            let result = null
            switch (this.currentOption) {
                case 0:
                    result = await messages
                        .getInboxMessages(this.user.id, this.page)
                        .catch((e) => console.error(e))
                    break
                case 1:
                    result = await messages
                        .getSentMessages(this.user.id, this.page)
                        .catch((e) => console.error(e))
                    break
                case 2:
                    result = await messages
                        .getArchivedMessages(this.user.id, this.page)
                        .catch((e) => console.error(e))
                    break
                case 3:
                    result = await messages
                        .getTrashMessages(this.user.id, this.page)
                        .catch((e) => console.error(e))
                    break
                default:
                    result = await messages
                        .getInboxMessages(this.user.id, this.page)
                        .catch((e) => console.error(e))
                    break
            }
            const { content, totalElements, totalPages } = result.content
            this.content = content
            this.totalElements = totalElements
            this.totalPages = totalPages
            this.selected.length = 0
            this.loadingData = false
        },
        mapMessagesIds() {
            if (!this.selected.length) return []

            const result = []
            this.selected.forEach((i) => result.push(i.id))
            return result
        },
        getMessageData() {
            return { idList: this.mapMessagesIds() }
        },
        validateSelected() {
            if (this.selected.length > 1) {
                this.$modal.error(this.$t('resources.only_one_message'))
                return false
            }

            return true
        },
        openReplyModal() {
            if (this.validateSelected()) this.setShowReplyModal()
        },
        openResendModal() {
            if (this.validateSelected()) this.setShowResendMessageModal()
        },
        async nextPage() {
            this.page = this.page + 1
            await this.getData()
        },
        async prevPage() {
            this.page = this.page - 1
            await this.getData()
        },
        async topAction(action = null) {
            if (!this.selected.length) {
                this.$modal.error(this.$t('resources.not_messages_selected'))
                return
            }

            this.loadingData = true

            let response = null
            switch (action) {
                case 'trash':
                    response = await messages
                        .setTrashMessages(this.getMessageData())
                        .catch((e) => console.error(e))
                    break
                case 'archived':
                    response = await messages
                        .setArchiveMessages(this.getMessageData())
                        .catch((e) => console.error(e))
                    break
                default:
                    return
            }
            this.loadingData = false
            await this.getData()
            return response
        },
    },
    async mounted() {
        await this.getData()
    },
    computed: {
        ...mapState({
            user: (state) => state.userData.user,
            token: (state) => state.token,
            showNewMessageModal: (state) => state.showNewMessageModal,
            showResendMessageModal: (state) => state.showResendMessageModal,
            showReplyModal: (state) => state.showReplyModal,
        }),
        headers() {
            return [
                {
                    text: this.$t('from'),
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                { text: this.$t('subject'), value: 'subject', align: 'start', sortable: false },
                { text: this.$t('date_time'), value: 'date', sortable: false },
            ]
        },
        mapContent() {
            return this.content.map((i) => {
                return {
                    id: i.message.id,
                    name: i.userFrom
                        ? this.$utils.getFullName(i.userFrom)
                        : this.$utils.getFullName(i.message.fromUser),
                    userId: i.userFrom?.id,
                    avatar: i.userFrom?.avatar,
                    subject: `${i.message.subject}`,
                    body: `${i.message.message}`,
                    date: i.message.issueDate,
                    message: i.message.message,
                }
            })
        },
    },
}
</script>

<style scoped>
.border-card-options {
    border: 2px solid #cbc6db;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background: #f7f6fb;
}

.border-card-message {
    -webkit-border-radius: 0 5px 5px 0;
    -moz-border-radius: 0 5px 5px 0;
    border-radius: 0 5px 5px 0;
}

.border-selected {
    border-top: 2px solid #cbc6db;
    border-bottom: 2px solid #cbc6db;
    background: white;
}
</style>
