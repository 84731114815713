<template>
    <div>
        <v-overlay v-model="dialog" color="primary" style="z-index: 6"></v-overlay
        ><v-dialog
            v-model="dialog"
            content-class="d-flex elevation-0 position-relative"
            transition="dialog-bottom-transition"
            persistent
            width="900"
            elevation="0"
            hide-overlay
        >
            <v-card
                elevation="0"
                color="white"
                class="position-relative cursor-pointer white overflow-hidden"
                v-if="dialog"
            >
                <v-container class="pa-0">
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-row class="modal-header pt-0">
                            <v-col cols="12" class="px-6 d-flex align-center">
                                <div>
                                    <span class="font-size-24 primary--text">{{
                                        $t('resources.new_message')
                                    }}</span>
                                </div>
                                <v-btn icon color="primary" class="ml-auto" @click="exitCard">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row class="px-10">
                            <v-col cols="12" class="py-0">
                                <v-row class="pa-0 d-flex flex-row align-center">
                                    <v-col cols="2" class="text-center pa-0">
                                        <span class="primary--text">{{ $t('resources.to') }}</span>
                                    </v-col>
                                    <v-col cols="10" class="pa-0">
                                        <v-autocomplete
                                            v-model="studentSelected"
                                            :items="studentList"
                                            :disabled="loadAction"
                                            :rules="studentsRules"
                                            chips
                                            dense
                                            color="primary lighten-2"
                                            item-text="name"
                                            item-value="id"
                                            multiple
                                            disabled
                                            required
                                        >
                                            <template v-slot:selection="data">
                                                <v-chip
                                                    v-bind="data.attrs"
                                                    :input-value="data.selected"
                                                    close
                                                    @click="data.select"
                                                    @click:close="removeStudents(data.item)"
                                                >
                                                    <v-avatar left>
                                                        <UserAvatar
                                                            :user-name="data.item.name"
                                                            :avatar="data.item.avatar"
                                                        ></UserAvatar>
                                                    </v-avatar>
                                                    {{ data.item.name }}
                                                </v-chip>
                                            </template>
                                            <template v-slot:item="data">
                                                <template v-if="typeof data.item !== 'object'">
                                                    <v-list-item-content
                                                        v-text="data.item"
                                                    ></v-list-item-content>
                                                </template>
                                                <template v-else>
                                                    <v-list-item-avatar>
                                                        <UserAvatar
                                                            :user-name="data.item.name"
                                                            :avatar="data.item.avatar"
                                                        ></UserAvatar>
                                                        <!--                                            <img :src="data.item.avatar" alt="user avatar" />-->
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title
                                                            v-html="data.item.name"
                                                        ></v-list-item-title>
                                                        <v-list-item-subtitle
                                                            v-html="data.item.class"
                                                        ></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" class="py-0">
                                <v-row>
                                    <v-col cols="2" class="pa-0 text-center">
                                        <span class="primary--text">{{
                                            $t('subject')
                                        }}</span></v-col
                                    >
                                    <v-col cols="10" class="pa-0">
                                        <v-text-field
                                            dense
                                            v-model="reSubject"
                                            :disabled="loadAction"
                                            :rules="subjectRules"
                                            :placeholder="$t('subject')"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" class="py-0">
                                <v-row>
                                    <v-col cols="2" class="pa-0 text-center">
                                        <span class="primary--text">{{
                                            $t('message')
                                        }}</span></v-col
                                    >
                                    <v-col cols="10" class="pa-0">
                                        <v-textarea
                                            :placeholder="$t('resources.new_message')"
                                            :rules="bodyRules"
                                            :disabled="loadAction"
                                            required
                                            v-model="bodyMessage"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row class="px-6 py-2">
                            <v-card-actions class="w-100">
                                <v-btn
                                    outlined
                                    color="secondary"
                                    class="ml-auto more-border text-capitalize"
                                    @click="sendMessage"
                                    :disabled="!valid"
                                    :loading="loadAction"
                                >
                                    <v-icon class="mr-2">mdi-send</v-icon>
                                    <span>{{ $t('resources.send_message') }}</span>
                                </v-btn>
                            </v-card-actions>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import UserAvatar from '../../core/UserAvatar'
import { mapActions, mapState } from 'vuex'
import messages from '../../../services/messages'
export default {
    name: 'ReplyMessageModal',
    components: { UserAvatar },
    props: {
        dialog: { type: Boolean, default: false },
        userId: { type: Number, default: 0 },
        reSubject: { type: String, default: '' },
    },
    mounted() {
        if (this.userId) this.studentSelected.push(this.userId)
    },
    methods: {
        ...mapActions(['setShowReplyModal']),
        exitCard() {
            this.$emit('close-modal')
        },
        removeStudents(item) {
            const index = this.studentSelected.indexOf(item.id)
            if (index >= 0) this.studentSelected.splice(index, 1)
        },
        async sendMessage() {
            if (this.$refs.form.validate()) {
                this.loadAction = true
                const data = {
                    userFrom: {
                        id: this.user.id,
                    },
                    userToList: [{ id: this.userId }],
                    message: {
                        subject: this.reSubject,
                        message: this.bodyMessage,
                    },
                    groupList: [],
                }
                await messages.sendMessage(data).catch((e) => console.error(e))
                this.setShowReplyModal()
            }
            this.loadAction = false
        },
    },
    computed: {
        ...mapState({
            students: (state) => state.userData.students,
            user: (state) => state.userData.user,
            courses: (state) => state.userData.courses,
        }),
        studentList() {
            let result = []
            this.courses.forEach((i) => {
                result.push({ header: i.name })
                const tempArr = this.students.filter((j) => j.course.id === i.id)
                result = [
                    ...result,
                    ...tempArr.map((k) => ({
                        name: this.$utils.getFullName(k),
                        id: k.id,
                        avatar: k.avatar,
                        class: i.name,
                    })),
                ]
            })

            return result
        },
        subjectRules() {
            return [(v) => !!v || this.$t('resources.subject_required')]
        },
        bodyRules() {
            return [(v) => !!v || this.$t('resources.body_required')]
        },
        studentsRules() {
            return [(v) => !!v || this.$t('resources.student_required')]
        },
    },
    data: () => ({
        loadAction: false,
        studentSelected: [],
        bodyMessage: '',
        rulesbody: [(v) => !!v || 'resources.body_required'],
        valid: true,
    }),
}
</script>

<style scoped>
.modal-header {
    background-color: #f7f6fb;
    border-bottom: 3px solid #cbc6db;
}
</style>
