import Services from './base-services'

const service = new Services('message')

export default {
    sendMessage(data: any) {
        return service.post(`sendMessage`, data)
    },
    reSendMessage(data: any) {
        return service.post(`reSendMessage`, data)
    },
    getInboxMessages(
        userId: number,
        pageNumber: string | number = 0,
        pageSize: number | string = 20
    ) {
        return service.get(
            `getInboxMessages?userId=${userId}&pageNumber=${pageNumber}&pageSize=${pageSize}`
        )
    },
    getSentMessages(
        userId: number,
        pageNumber: string | number = 0,
        pageSize: number | string = 20
    ) {
        return service.get(
            `getSentMessages?userId=${userId}&pageNumber=${pageNumber}&pageSize=${pageSize}`
        )
    },
    setArchiveMessages(data: any) {
        return service.put(`setArchiveMessages`, data)
    },
    getArchivedMessages(
        userId: number,
        pageNumber: string | number = 0,
        pageSize: number | string = 20
    ) {
        return service.get(
            `getArchivedMessages?userId=${userId}&pageNumber=${pageNumber}&pageSize=${pageSize}`
        )
    },
    setTrashMessages(data: any) {
        return service.put(`setTrashMessages`, data)
    },
    getTrashMessages(
        userId: number,
        pageNumber: string | number = 0,
        pageSize: number | string = 20
    ) {
        return service.get(
            `getTrashMessages?userId=${userId}&pageNumber=${pageNumber}&pageSize=${pageSize}`
        )
    },
    setInboxMessages(data: any) {
        return service.put(`setInboxMessages`, data)
    },
    deleteSendMessages(data: any) {
        return service.delete(`deleteSendMessages`, data)
    },
    deleteReceivedMessages(userId: number, data: any) {
        return service.delete(`deleteReceivedMessages?userId=${userId}`, data)
    },
}
