<template>
        <MessageCards></MessageCards>
</template>

<script>
    import MessageCards from './core/MessageCards'
    export default {
        name: 'Messages',
        components: { MessageCards },
    }
</script>

<style scoped>

</style>
