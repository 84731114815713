<template>
    <v-dialog v-model="dialog" elevation="0" width="700" persistent>
        <v-card class="mx-auto pa-5" v-if="true">
            <v-card-text>
                <div>
                    {{ `${$t('resources.from')}` }}
                    <span class="text-body-1 font-primary primary--text">{{ message.name }}</span>
                </div>
                <v-card-title class="display-1 px-0 pt-0 mb-0 text--primary">{{
                    message.subject
                }}</v-card-title>
                <v-card-subtitle class="px-0">{{
                    $moment(message.date).format('MMM DD YYYY / hh:mm')
                }}</v-card-subtitle>
                <v-card-text class="px-0" v-html="message.body"></v-card-text>
            </v-card-text>
            <v-card-actions>
                <v-btn rounded color="secondary" class="px-5 ml-auto" @click="close">{{
                    $t('exit')
                }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'MessageModal',
    props: {
        dialog: { type: Boolean, default: false },
        message: { type: Object, default: () => null },
    },
    methods: {
        close() {
            this.$emit('close-modal')
        },
    },
}
</script>

<style scoped>
.font-primary {
    font-family: 'Space Grotesk', cursive !important;
}
</style>
